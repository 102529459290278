import React, { PureComponent } from "react";
import { Redirect, Link } from "react-router-dom";
import { withRouter } from "react-router";
import {
  Button,
  Row,
  Col,
  Input,
  Layout,
  Form,
  Alert,
  Modal,
  message,
  Switch,
  Tabs,
  notification,
  Spin,
  Pagination,
  Tag,
  Breadcrumb,
  Card,
  Space,
  Table,
  Popconfirm,
  Skeleton,
  Select,
  InputNumber,
  Radio,
  Checkbox,
  DatePicker,
} from "antd";
import {
  TeamOutlined,
  SearchOutlined,
  PlusOutlined,
  FormOutlined,
  CloseSquareOutlined,
  ExclamationCircleOutlined,
  DiffOutlined,
  RedoOutlined,
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
  SaveOutlined,
} from "@ant-design/icons";
// import "@ant-design/compatible/assets/index.css";
import Config from "../Config";
import { Helmet } from "react-helmet";

import { connect } from "react-redux";
import { setConfig, ajaxHandler, ajaxViewHandler } from "../store/actions";

import moment from "moment";

import axios from "axios";
import Cookies from "js-cookie";

class LangkahAudit extends PureComponent {
  config;
  timeoutGetQr;
  timeOutPushQr;
  formRef = React.createRef();
  formRefKeterangan = React.createRef();
  formRefModal = React.createRef();
  focusComponent = "";
  pk = "";

  constructor(props) {
    super(props);
    this.config = new Config();
    this.state = {
      errTitle: "",
      errMsg: "",
      loadingData: false,
      pagination: { pageSize: 10, current: 1, total: 0 },
      searchText: "",
      dataSource: [],
      prevSource: [],
      columns: [
        {
          title: "Aksi",
          width: 120,
          fixed: "left",
          dataIndex: "id",
          key: "aksi",
          align: "center",
          render: (value, row, index) => {
            return (
              <Button
                type="text"
                danger
                htmlType="button"
                onClick={() =>{
                  this.showDeleteConfirm(value)
                }}
              >
                <DeleteOutlined />
              </Button>
            )
          },
        },
        {
          title: "Kode",
          width: 150,
          dataIndex: "kode",
          key: "kode",
        },
        {
          title: "Judul",
          width: 150,
          dataIndex: "judul",
          key: "judul",
        },
        {
          title: "Nama",
          width: 150,
          dataIndex: "nama",
          key: "nama",
        },
        {
          title: "Tujuan",
          width: 150,
          dataIndex: "tujuan",
          key: "tujuan",
        },
        {
          title: "Prosedur Audit",
          width: 150,
          dataIndex: "prosedur_audit",
          key: "prosedur_audit",
          render: (value, row, index) => {
            return JSON.stringify(value)
          },
        },
      ],
      tambah: false,
      aksi: "tambah",
      method: "post",
      idLama: "",
      editingKey: "",
      open: false,

      prosedur_audit: [
        {
          nama: null,
          nomor: null,
        },
      ],
    };
  }

  showInsert() {
    this.setState(
      {
        open: true,
        aksi: "tambah",
        idLama: "",
        method: "post",
        editingKey: "",
      },
      () => {}
    );
  }

  showEdit = (record) => {
    this.formRef.current.resetFields();

    let tempData = this.state.dataSource;

    this.setState(
      {
        prevSource: tempData,
        editingKey: record.id,
      },
      () => {
        this.preview(record.id);
      }
    );
    // this.formRef.current.setFieldsValue({
    //   nama: '',
    //   ...record,
    // });
    // this.setState({
    //   editingKey: record.id
    // }, console.log('editingkey', record.id));
  };

  async preview(id) {
    this.setState({
      loadingData: true,
      errTitle: "",
      errMsg: "",
    });

    this.props
      .ajaxViewHandler("get", this.props.configClass.apiUrl.preview + id)
      .then(() => {
        // setTimeout(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          // this.formRef.current.setFieldsValue(data);
          for (var key in data) {
            const dateRegex = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/;
            const dateTimeRegex =
              /^[0-9]{4}\-[0-9]{2}\-[0-9]{2} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;
            if (dateRegex.test(data[key])) {
              //jika format tanggal
              this.formRef.current.setFieldsValue({
                [key + "_picker"]: moment(new Date(data[key]), "DD-MM-YYYY"),
                [key]: moment(new Date(data[key]), "DD-MM-YYYY"),
              });
            } else if (dateTimeRegex.test(data[key])) {
              //jika format tanggal dan jam
              this.formRef.current.setFieldsValue({
                [key + "_picker"]: moment(
                  new Date(data[key]),
                  "DD-MM-YYYY HH:mm:ss"
                ),
                [key]: moment(new Date(data[key]), "DD-MM-YYYY HH:mm:ss"),
              });
            } else {
              if (key == "body") {
                this.setState({
                  artikelString: data[key],
                });
                this.formRef.current.setFieldsValue({
                  content_editor: data[key],
                  body_editor: data[key],
                });
              } else if (key == "tag") {
                let temp = [];
                data[key].map((item) => {
                  temp = [
                    ...temp,
                    {
                      value: item.id,
                      label: item.nama,
                      key: item.id,
                    },
                  ];
                });
                this.formRef.current.setFieldsValue({
                  tags: temp,
                });
              } else if (key == "image" || key == "dokumen") {
                // let temp = [];
                // let tempGb = [];
                // data[key].map((item) => {
                //   temp = [
                //     ...temp,
                //     {
                //       id: item.id,
                //       name: item.name,
                //     },
                //   ];
                //   tempGb = [
                //     ...tempGb,
                //     {
                //       id: item.id,
                //       name: item.name,
                //       path: item.path,
                //     },
                //   ];
                // });
                // this.setState({
                //   gbr_post: temp,
                //   gambar_edit: tempGb,
                // });
              } else {
                this.formRef.current.setFieldsValue({
                  [key]: data[key],
                });
              }
            }

            // if (key == "icon") {
            //   this.setState({
            //     preview_gambar: data[key],
            //   });
            // }
          }
          message.destroy();
          this.setState(
            {
              aksi: "edit",
              method: "put",
              idLama: data.id + "/",
              loadingData: false,
            },
            () => {
              if (this.focusComponent != null && this.focusComponent != "") {
                document.getElementById(this.focusComponent).focus();
              }
            }
          );
        } else {
          const errTitle = "error preview data lama";
          this.setState({
            errTitle: errTitle,
            errMsg: JSON.stringify(data),
            loadingData: false,
          });
        }
        // }, 500);
      })
      .catch((response) => {
        this.setState({
          errTitle: "gagal get data",
          errMsg: JSON.stringify(response),
          loadingData: false,
        });
      });
  }

  // editableCell = (
  //   editing,
  //   dataIndex,
  //   title,
  //   inputType,
  //   record,
  //   index,
  //   children,
  //   ...restProps
  // ) => {
  //   const inputNode = <Input />;
  //   return (
  //     <td>
  //       {editing != undefined && editing ? (
  //         <Form.Item
  //           name={dataIndex}
  //           style={{
  //             margin: 0,
  //           }}
  //           rules={[
  //             {
  //               required: true,
  //               message: `Please Input ${title}!`,
  //             },
  //           ]}
  //         >
  //           {inputNode}
  //         </Form.Item>
  //       ) : (
  //         children
  //       )}
  //     </td>
  //   );
  // };

  componentDidMount() {
    // console.log('didmount', this.props)
    this.getData();
  }

  addProsedur() {
    let temp = this.state.prosedur_audit;
    temp = [
      ...temp,
      {
        nama: null,
        nomor: null,
      },
    ];
    this.setState({
      prosedur_audit: temp,
    });
  }

  hapusProsedur(idx) {
    const temp = [...this.state.prosedur_audit];
    temp.splice(idx, 1);
    this.setState({
      prosedur_audit: temp,
    });
  }

  async getData(
    limit = this.state.pagination.pageSize,
    offset = 0,
    search = this.state.searchText
  ) {
    this.setState({ loadingData: true, errTitle: "", errMsg: "" });

    let baseUrl = this.props.configClass.apiUrl.data;
    let url = "";
    if (baseUrl.includes("?")) {
      url =
        baseUrl +
        "&limit=" +
        limit +
        "&offset=" +
        offset +
        "&search=" +
        search;
    } else {
      url =
        baseUrl +
        "?limit=" +
        limit +
        "&offset=" +
        offset +
        "&search=" +
        search;
    }

    this.props
      .ajaxViewHandler("get", url)
      .then((results) => {
        // setTimeout(() => {
        const res = this.props.responseMsg;
        if (this.props.isSuccess) {
          let pagination = { ...this.state.pagination };
          pagination.total = res.count;
          pagination.current = offset + 1;
          this.setState({
            loadingData: false,
            dataSource: res.results,
            pagination,
          });
        } else {
          const errTitle = "error get data code 1";
          this.setState({
            loadingData: false,
            errTitle: errTitle,
            errMsg: JSON.stringify(res),
          });
        }
        // }, 500);
      })
      .catch((response) => {
        const errTitle = "error get data code 2";
        this.setState({
          loadingData: false,
          errTitle: errTitle,
          errMsg: JSON.stringify(response),
        });
      });
  }

  handleTableChange = async (pagination, filters, sorter) => {
    const newPagination = { ...this.state.pagination };
    newPagination.pageSize = pagination.pageSize;
    this.setState({
      loadingData: true,
      pagination,
    });
    if (pagination.current == 1) {
      this.getData(pagination.pageSize);
    } else {
      this.getData(
        pagination.pageSize,
        (pagination.current - 1) * pagination.pageSize
      );
    }
  };

  showDeleteConfirm(item) {
    Modal.confirm({
      title: "Konfirmasi Hapus?",
      icon: <ExclamationCircleOutlined />,
      content: "yakin menghapus data?",
      okText: "Ya",
      okType: "danger",
      cancelText: "Tidak",
      onOk: () => this.prosesHapus(item),
      onCancel() {
        //console.log('Cancel');
      },
    });
  }

  resetStatePost() {
    this.setState({
      aksi: "tambah",
      method: "post",
      idLama: "",
      tambah: false,
      editingKey: "",
      prosedur_audit: [
        {
          nama: null,
          nomor: null,
        },
      ],
      open: false,
    });
  }

  async postData(datas) {
    this.setState({
      loadingData: true,
      errTitle: "",
      errMsg: "",
    });
    // message.loading({ content: 'Memproses...', key: 'toast' });

    this.props
      .ajaxHandler(
        this.state.method,
        this.props.configClass.apiUrl.preview + this.state.idLama,
        datas,
        true,
        true
      )
      .then((res) => {
        // setTimeout(() => {
        this.setState({
          loadingData: false,
        });

        if (res.type == "SUCCESS_HANDLER") {
          notification.success({
            message: "sukses",
            description: "berhasil menyimpan.",
            placement: "bottomRight",
          });
          this.resetStatePost();
          this.getData();
        } else {
          notification.error({
            message: "gagal",
            description: "gagal menyimpan.",
            placement: "bottomRight",
          });
          const errTitle = "gagal menyimpan data";
          // console.log("tes", res);
          this.setState({
            errTitle: errTitle,
            errMsg:
              res.error.response.data.message != undefined
                ? JSON.stringify(res.error.response.data.message)
                : errTitle,
          });
        }
        // }, 0);
      })
      .catch((response) => {
        notification.error({
          message: "gagal",
          description: "gagal menyimpan.",
          placement: "bottomRight",
        });
        this.setState({
          loadingData: false,
          errTitle: "gagal menyimpan",
          errMsg: JSON.stringify(response),
        });
      });
  }

  showActiveConfirm(item) {
    Modal.confirm({
      title: "Konfirmasi Activate?",
      icon: <ExclamationCircleOutlined />,
      content: "yakin mengaktivate data?",
      okText: "Ya",
      okType: "danger",
      cancelText: "Tidak",
      onOk: () => this.prosesActivate(item),
      onCancel() {
        //console.log('Cancel');
      },
    });
  }

  prosesHapus(id) {
    this.setState({
      loadingData: true,
      errMsg: "",
      errTitle: "",
    });

    this.props
      .ajaxHandler("delete", this.props.configClass.apiUrl.hapus + id + "/")
      .then((res) => {
        if (res.type == "SUCCESS_HANDLER") {
          notification.success({
            message: "sukses",
            description: "berhasil menghapus.",
            placement: "bottomRight",
          });
          this.getData();
        } else {
          this.setState({
            errTitle: "gagal menghapus",
            errMsg: JSON.stringify(res.payload.data.message),
            loadingData: false,
          });
        }
      })
      .catch((response) => {
        this.setState({
          loadingData: false,
          errTitle: "gagal menghapus",
          errMsg: JSON.stringify(response),
        });
      });
  }

  prosesActivate(id) {
    this.setState({
      loadingData: true,
      errMsg: "",
      errTitle: "",
    });

    this.props
      .ajaxHandler(
        "get",
        this.props.configClass.apiUrl.data + "active/" + id + "/"
      )
      .then((res) => {
        if (res.type == "SUCCESS_HANDLER") {
          notification.success({
            message: "sukses",
            description: "berhasil mengactivate data.",
            placement: "bottomRight",
          });
          this.getData();
        } else {
          this.setState({
            errTitle: "gagal mengactivate data",
            errMsg: JSON.stringify(res.payload.data.message),
            loadingData: false,
          });
        }
      })
      .catch((response) => {
        this.setState({
          loadingData: false,
          errTitle: "gagal menghapus",
          errMsg: JSON.stringify(response),
        });
      });
  }

  handleKeyUp = (event) => {
    // Enter
    if (event.keyCode === 13) {
      this.formRef.current.submit();
    }
  };

  handleSubmit = (values) => {
    var params = {};
    for (var key in values) {
      if (values[key] == undefined) {
        params = { ...params, [key]: "" };
      } else {
        // datas.append(key, values[key].toString());
        params = { ...params, [key]: values[key].toString() };
      }
    }
    params = { ...params, prosedur_audit: this.state.prosedur_audit };

    this.postData(params);
  };

  handleSubmitKeterangan = (values) => {
    for (var key in values) {
      // console.log("document.getElementById("+key+").value = "+values[key]+")")
      document.getElementById(key + "_preview").innerHTML = values[key];
      // document.getElementById(key).value = values[key];
      // document.querySelector("#"+key).innerText  = values[key];
      this.formRef.current.setFieldsValue({
        [key]: values[key],
      });
    }
    this.setState({
      openKeterangan: false,
    });
  };

  render() {
    return (
      <>
        {/*<!-- BEGIN: Content-->*/}
        <Breadcrumb>
          {this.props.breadCrumb.map((item, idx) => (
            <Breadcrumb.Item key={"bread" + idx}>{item.label}</Breadcrumb.Item>
          ))}
        </Breadcrumb>
        <div className="card">
          <Row className="isi">
            <Col span={24}>
              <Card
                className="konten"
                title={
                  <Row>
                    <Col lg={{ span: 20 }} xs={{ span: 24 }}>
                      <span className="konten-judul">
                        {this.props.configClass.name}
                      </span>
                    </Col>
                    <Col
                      lg={{ span: 4 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      style={{ textAlign: "right" }}
                    >
                      <Space>
                        <Button
                          loading={this.state.loadingData}
                          type="default"
                          size="small"
                          onClick={() => this.getData()}
                        >
                          <RedoOutlined />
                        </Button>
                        <Button
                          disabled={
                            this.state.editingKey != "" || this.state.tambah
                              ? true
                              : false
                          }
                          loading={this.state.loadingData}
                          size="small"
                          type="primary"
                          onClick={() => {
                            this.showInsert();
                          }}
                        >
                          <PlusOutlined /> tambah
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                }
              >
                <Form
                  name="finput"
                  // onKeyUp={this.handleKeyUp}
                  onFinish={this.handleSubmit}
                  autoComplete="off"
                  ref={this.formRef}
                >
                  <Table
                    bordered
                    columns={this.state.columns}
                    dataSource={this.state.dataSource}
                    loading={this.state.loadingData}
                    scroll={{ x: "max-content", y: 450 }}
                    size="small"
                    pagination={this.state.pagination}
                    rowKey={this.pk}
                    onChange={this.handleTableChange}
                    title={() => (
                      <Row>
                        <Col lg={8} sm={24} xs={24}>
                          <span className="konten-judul">
                            Total {this.state.pagination.total} record
                          </span>
                        </Col>
                        <Col lg={{ span: 8, offset: 8 }} sm={24} xs={24}>
                          <Row>
                            <Col span={18}>
                              <Form.Item>
                                <Input.Search
                                  size="small"
                                  placeholder="ketikkan pencarian"
                                  onPressEnter={(e) => {
                                    e.preventDefault();
                                  }}
                                  onSearch={(value) => {
                                    this.setState(
                                      {
                                        searchText: value,
                                      },
                                      () => {
                                        this.getData();
                                      }
                                    );
                                  }}
                                  allowClear
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )}
                  />
                </Form>
              </Card>
            </Col>
          </Row>
        </div>

        <Modal
          title={
            <div style={{ textAlign: "center" }}>
              {"TAMBAH " + this.props.configClass.name}
            </div>
          }
          centered
          open={this.state.open}
          onCancel={() => this.setState({ open: false })}
          width={"95%"}
          footer={[]}
        >
          <Spin spinning={this.state.loadingData}>
            <Form
              name="finsert"
              // labelCol={{
              //   span: 6,
              // }}
              // wrapperCol={{
              //   span: 18,
              // }}
              layout="vertical"
              onFinish={this.handleSubmit}
              autoComplete="off"
              ref={this.formRefModal}
              // style={{
              //   maxWidth: 600,
              // }}
              // initialValues={{
              //   remember: true,
              // }}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="KODE"
                    name={"kode"}
                    rules={[
                      {
                        required: true,
                        message: "harus diisi",
                      },
                    ]}
                  >
                    <Input placeholder="Input Kode" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="NAMA"
                    name={"nama"}
                    rules={[
                      {
                        required: true,
                        message: "harus diisi",
                      },
                    ]}
                  >
                    <Input placeholder="Input Nama" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="JUDUL"
                    name={"judul"}
                    rules={[
                      {
                        required: true,
                        message: "harus diisi",
                      },
                    ]}
                  >
                    <Input placeholder="Input Judul" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="TUJUAN"
                    name={"tujuan"}
                    rules={[
                      {
                        required: true,
                        message: "harus diisi",
                      },
                    ]}
                  >
                    <Input placeholder="Input Tujuan" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={10}>Nama</Col>
                <Col span={10}>Nomor</Col>
              </Row>

              {this.state.prosedur_audit.map((item, idx) => (
                <Row gutter={[16, 16]} key={"prosedur_audit" + idx}>
                  <Col span={10}>
                    <Form.Item
                      // name={"nama_prosedur" + idx}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "harus dipilih",
                      //   },
                      // ]}
                    >
                      <Input
                        value={item.nama}
                        placeholder="Input Nama"
                        onChange={(e) =>{
                          const temp = [...this.state.prosedur_audit];
                          temp[idx]["nama"] = e.target.value;
                          this.setState({
                            prosedur_audit: temp
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      // name={"nomor_prosedur" + idx}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "harus dipilih",
                      //   },
                      // ]}
                    >
                      <Input
                        value={item.nomor}
                        placeholder="Input Nomor"
                        onChange={(e) =>{
                          const temp = [...this.state.prosedur_audit];
                          temp[idx]["nomor"] = e.target.value;
                          this.setState({
                            prosedur_audit: temp
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4} style={{ textAlign: "right" }}>
                    {
                      idx == this.state.prosedur_audit.length - 1 ? (
                        <Button
                          key={"btntambah"+idx}
                          size="small"
                          type="primary"
                          onClick={() => {
                            this.addProsedur();
                          }}
                        >
                          <PlusOutlined /> tambah
                        </Button>
                      ) : (
                          <Button
                          key={"btnhapus"+idx}
                          size="small"
                          type="primary"
                          danger
                          onClick={() => {
                            this.hapusProsedur(idx)
                          }}
                        >
                            <DeleteOutlined /> hapus
                        </Button>
                      )
                      // <Button
                      //   size="small"
                      //   type="primary"
                      //   danger
                      //   onClick={() => {
                      //     this.hapusAnggota(idx)
                      //   }}
                      // >
                      //     <DeleteOutlined /> hapus
                      // </Button>
                    }
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Space>
                  
                    <Button htmlType="submit" type="primary" title="simpan">
                      <SaveOutlined /> Simpan
                    </Button>
                  

                  <Button
                    htmlType="button"
                    type="primary"
                    danger
                    title="batalkan"
                    onClick={() => {
                      this.setState({
                        open: false,
                      });
                    }}
                  >
                    <CloseSquareOutlined /> Batal
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  setConfig,
  ajaxHandler,
  ajaxViewHandler,
})(withRouter(LangkahAudit));
