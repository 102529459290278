import React, { PureComponent } from 'react'
import { Redirect, Link } from "react-router-dom";
import { 
  Button, 
  Row, 
  Col,
  Input, 
  Layout, 
  Form, 
  Alert,
  Modal,
  message,
  Spin
} from 'antd';
// import '@ant-design/compatible/assets/index.css'

import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Config from '../Config';
import {Helmet} from "react-helmet";

import { connect } from 'react-redux';
import { 
  setConfig, 
  ajaxHandler, 
  ajaxViewHandler 
} from "../store/actions";

import axios from 'axios';
import Cookies from 'js-cookie';
import QRCode from 'qrcode.react';

// import 'antd/dist/antd.css';
import '../assets/login.css';

const configClass = {
  apiUrl: {
    cekLogin: "api/token/"
  }
}

class AuthLayout extends PureComponent {
  config;
  timeoutGetQr;
  timeOutPushQr;

  constructor(props){
    super(props);
    this.config = new Config();
    this.state = {
      msg: '',
      loading: false,
      loadingRedirect: false,
      logo: this.config.get_site_info().logo,
      isSukses: false,
    }
  }

  componentDidMount(){

  }

  handleSubmit = (values) => {
    //e.preventDefault();
    this.postLogin(values);
  }

  async postLogin(datas) {
    //message.loading({ content: 'Memproses...', duration: 2, key: 'toast' });
    this.setState({
        loading: true,
        msg: '',
    });

    this.props.ajaxHandler('post', configClass.apiUrl.cekLogin, datas, false, true)
    .then(() =>{
      this.setState({
        loading: false
      });
      if(this.props.isSuccess){
        Cookies.set(this.config.get_cookie_name(), 'Bearer '+this.props.responseMsg.access, { expires: 1 });
        this.setState({
          loading: false,
          isSukses: true,
        });
        
          this.props.setConfig({
            isLogin: true
          });
        
      }else{
        this.setState({
          msg: this.props.responseMsg.detail != undefined ? this.props.responseMsg.detail : JSON.stringify(this.props.responseMsg)
        });
      }
    }).catch((response) => {
      console.log('gagal_post', response);
      this.setState({
          loading: false,
          msg: JSON.stringify(response)
        });
      // message.error({ content: 'kesalahan pengiriman data, code: '+response.responseCode+'coba lagi', duration: 2, key: 'toast' });
    });
  }

  render() {
    if(this.props.isLogin){
      return <Redirect to="/admin/index" />
    }
    
    return (
      <div className="bg-login">
        <Helmet>
          <title>login | {this.config.get_site_info().name}</title>
        </Helmet>
        
           
              <div className="form-login">
                <Spin
                  spinning={this.state.loading}
                >
                <div className="logo-login" style={{marginBottom: 10}}>
                  
                    <img alt="logo" src={this.state.logo}  />
                    <br />
                    <span>{this.config.get_site_info().name}</span>
                  
                </div>
                {this.state.msg!='' &&
                  <Row justify="center" style={{marginBottom: 10}}>
                    <Col span="24">
                      <Alert message={this.state.msg} type="error" showIcon />
                    </Col>
                  </Row>
                }
                <Form 
                  className="flogin"
                  name="login"
                  layout="vertical"
                  onFinish={this.handleSubmit}
                >
                  <Form.Item
                    name="username"
                    rules={[{ required: true, message: 'username harus diisi!' }]}
                  >
                      <Input
                        placeholder="Username"
                        style={{textTransform: 'none'}}
                      />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'password harus diisi!' }]}
                  >
                    <Input
                      type="password"
                      placeholder="Password"
                      style={{textTransform: 'none'}}
                    />
                  </Form.Item>
                  <Row>
                    <Col span={24}>
                      <Button
                        type="primary"
                        loading={this.state.loading}
                        htmlType="submit"
                        block
                      >
                        Login
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <div className="footer-login">
                      &copy; 2023 {this.config.get_site_info().name}
                </div>
                </Spin>
              </div>
            

      </div>
    )
  }
}

const mapStateToProps = function(state) {
  return state.configReducers
}

export default connect(mapStateToProps, 
                        {
                          setConfig,
                          ajaxHandler,
                          ajaxViewHandler
                        }
                  )(AuthLayout);
