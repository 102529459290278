/*
*alwi89
*jagosoftware.com
*free use
*but please don't remove
*this header
*thanks
*/
import moment from 'moment';
import Cookies from 'js-cookie';
import axios from 'axios';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import Config from '../Config';
import { 
	SET_CONFIG, 
	INITIALIZE_HANDLER,
	AJAX_HANDLER,
	SUCCESS_HANDLER,
	FAILURE_HANDLER,
	AJAX_VIEW_HANDLER,
	SUCCESS_VIEW,
	SUCCESS_DOWNLOAD,
} from "./actionTypes";

let config = new Config();

export type Action =
    {
        type: 'SUCCESS_HANDLER',
        payload: {
        	success: AxiosResponse
        }
    } | {
        type: 'FAILURE_HANDLER',
        payload: {
        	error: AxiosResponse
        }
    } | {
        type: 'AJAX_HANDLER',
        payload: {
            request: AxiosRequestConfig
        }
    } | {
        type: 'AJAX_VIEW_HANDLER',
        payload: {
            request: AxiosRequestConfig
        }
    } | {
        type: 'SUCCESS_VIEW',
        payload: {
            success: AxiosResponse
        }
    } | {
        type: 'SUCCESS_DOWNLOAD',
        payload: {
            success: AxiosResponse
        }
    };
export const GET_VALUES_SUCCESS = 'APP_GET_VALUES_SUCCESS';
/*
global
*/
export const setConfig = newConfig => ({
  type: SET_CONFIG,
  payload: {
    newConfig
  }
});

//export const AJAX_HANDLER = 'AJAX_HANDLER';
export const initHandler = () => ({
	type: INITIALIZE_HANDLER
});

export const ajaxHandler = (_method, _url, _datas=null, _isForm=false, _noUpercase=false, _spesialToken=''): Action =>{
	var _clear_datas = _datas;
	for ( var key in _datas ) {
		/*
		const dateRegex = /^[0-9]{2}\-[0-9]{2}\-[0-9]{4}/;
		const dateTimeRegex = /^[0-9]{2}\-[0-9]{2}\-[0-9]{4} [0-9]{2}\:[0-9]{2}\:[0-9]{2}/;
		if(dateRegex.test(_datas[key])){//jika format tanggal
			_clear_datas = {..._clear_datas, [key]: moment(_datas[key]).format('DD-MM-YYYY')+""};
		}else if(dateTimeRegex.test(_datas[key])){//jika format tanggal dan jam			    	
			_clear_datas = {..._clear_datas, [key]: moment(_datas[key]).format('DD-MM-YYYY HH:mm')+""};
		}else{//normal
			 _clear_datas = {..._clear_datas, [key]: (_datas[key]+"").toUpperCase()};
		}
		*/
		//_clear_datas = {..._clear_datas, [key]: (_datas[key]+"").toUpperCase()};
		if(Array.isArray(_datas[key])){
			_clear_datas = {..._clear_datas, [key]: _datas[key]};
		}else if(typeof _datas[key] === 'object'){
			console.log('objectbos', [key]+': '+_datas[key]);
			_clear_datas = {..._clear_datas, [key]: _datas[key]};
		}else{
			if(_noUpercase){
				_clear_datas = {..._clear_datas, [key]: _datas[key]};
			}else{
				_clear_datas = {..._clear_datas, [key]: (_datas[key]+"").toUpperCase()};
			}
		}
	}
	return {
		types: [AJAX_HANDLER, SUCCESS_HANDLER, FAILURE_HANDLER],
		payload: {
			request: {
				method: _method,
				url: _url,
				data: _isForm?_datas:_clear_datas,
				headers: {
	                // "Content-type": "application/json; charset=UTF-8",
	                "Authorization": _spesialToken==''?Cookies.get(config.get_cookie_name()):_spesialToken
	            }
			}
		}
	}
}

export const ajaxViewHandler = (_method, _url, _datas=null): Action =>{
	return {
		types: [AJAX_VIEW_HANDLER, SUCCESS_VIEW, FAILURE_HANDLER],
		payload: {
			request: {
				method: _method,
				url: _url,
				data: _datas,
				headers: {
	                "Content-type": "application/json; charset=UTF-8",
	                "Authorization": Cookies.get(config.get_cookie_name())
	            }
			}
		}
	}
}

export const ajaxDownloadHandler = (_method, _url, _datas=null): Action =>{
	return {
		types: [AJAX_VIEW_HANDLER, SUCCESS_DOWNLOAD, FAILURE_HANDLER],
		payload: {
			request: {
				method: _method,
				url: _url,
				data: _datas,
				headers: {
	                //"Content-type": "application/json; charset=UTF-8",
	                "Authorization": Cookies.get(config.get_cookie_name())
	            },
	            responseType: 'blob',
			}
		}
	}
}