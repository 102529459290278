import React, { Component }  from 'react';

class Config{
	/*
	jenis layanan: 1 home care, ambulance 2, laboratorium 3, obat 4
	*/
	get_site_info(){
		return {
			name: 'E-Audit',
			logo: process.env.PUBLIC_URL + "/logo_app.png",
			logo_landscape: process.env.PUBLIC_URL + "/logo_landscape.png",
			description: 'E-Audit'
		}
	}

	get_server_url(){
		//return 'https://www.googleapis.com/books/v1/volumes';
		 return 'https://api-eaudit.proitdev.com/';
		 // return 'http://localhost:8000/';
		//return 'https://doro.proitdev.com/';
		// return 'http://192.168.100.11:8000/';
		//return 'http://192.168.100.116:8000/';
	}
	
	get_base_url(){
		//return 'https://www.googleapis.com/books/v1/volumes';
		return 'https://api-eaudit.proitdev.com/';
		// return 'http://localhost:8000/';
		//return 'https://doro.proitdev.com/';
		// return 'http://192.168.100.11:8000/';
		//return 'http://192.168.100.116:8000';
	}

	get_web_socket(){
		return 'wss://api-slamet.proitdev.com/messages/provider/';
	}

	get_cookie_name(){
		return 'aud1_cr4uth';
	}

	stripTags(teks){
		return teks.replace(/(<([^>]+)>)/ig, "");//strip tags html
	}

	escape_str(value){
		return(
			<div>
				{value.includes("https://") || value.includes("http://") || value.includes("www.")
					?
						<div>
								<a href={value} target="_blank">{value}</a>
						</div>
					:
						value
				}
			</div>
		);
	}
}
export default Config
